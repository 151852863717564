import axios from '@/api/index'

// 新增供应商数据
export const Addsupplier = data => {
  return axios.request({
    url: '/supplier/create',
    method: 'post',
    data,
  })
}

// 业务负责人
export const Drop = params => {
  return axios.request({
    url: 'supplier/users',
    method: 'get',
    params
  })
}

// 供应商列表
export const Getlist = params => {
  return axios.request({
    url: '/supplier/list',
    method: 'get',
    params
  })
}


// 供应商类型
export const Type = data => {
  return axios.request({
    url: '/supplier/types',
    method: 'get',
    data
  })
}

// 获取用户详情信息
export const Detailsgong = params => {
  return axios.request({
    url: `/supplier/info?`,
    method: 'get',
    params
  })
}

// 供应商修改
export const EndCompile = data => {
  return axios.request({
    url: `/supplier/edit`,
    method: 'post',
    data
  })
}

