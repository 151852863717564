<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>批次管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="top">
        <div class="tops">
          <span>仓库名称 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入仓库关键字"
            v-model="house"
            :fetch-suggestions="cangvalidateCounts"
            value-key="name"
            @select="canghandleSelect($event)"
            @clear="cangqing"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>验收人 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="yanuser"
            :fetch-suggestions="yanvalidateCounts"
            value-key="name"
            @select="yanhandleSelect($event, '验收人')"
            @clear="yanqing"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>日期筛选 :</span>
          <el-date-picker
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="batch_no" label="批次号"></el-table-column>
        <el-table-column prop="purchase_nos" label="采购单号"></el-table-column>
        <el-table-column prop="house_name" label="目标仓库"></el-table-column>
        <el-table-column prop="receive_time" label="验收日期"></el-table-column>
        <el-table-column prop="receive_name" label="验收人"></el-table-column>
        <el-table-column prop="order_users" label="入库人"></el-table-column>
        <el-table-column prop="batch_status_desc" label="状态"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editfun(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <el-dialog title="批次详情" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" center>
      <div class="xianglist">
        <div class="xiang">
          <div class="tan">
            <div class="let">批次单号</div>
            <div class="el-select"><el-input v-model="rows.batch_no" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">采购单号</div>
            <div class="el-select"><el-input v-model="rows.purchase_nos" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">采购人</div>
            <div class="el-select"><el-input v-model="rows.purchase_users" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">目标仓库</div>
            <div class="el-select"><el-input v-model="rows.house_name" disabled></el-input></div>
          </div>
        </div>
        <div>
          <div class="tan">
            <div class="let">验收人</div>
            <div class="el-select"><el-input v-model="rows.receive_name" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">验收时间</div>
            <div class="el-select"><el-input v-model="rows.receive_time" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">入库人</div>
            <div class="el-select"><el-input v-model="rows.order_users" disabled></el-input></div>
          </div>
          <div class="tan">
            <div class="let">入库时间</div>
            <div class="el-select"><el-input v-model="rows.order_date" disabled></el-input></div>
          </div>
        </div>
      </div>
      <div class="nav">
        <h2>批次货物清单</h2>
        <el-button type="primary" @click="daochu" class="nav-btn">批次条码下载</el-button>
      </div>
      <el-table :data="rows.goods_list" stripe style="width: 100%">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="goods_no" label="货物编号"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称"></el-table-column>
        <el-table-column prop="goods_desc" label="货物详情"></el-table-column>
        <el-table-column prop="goods_unit" label="单位" align="center"></el-table-column>
        <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Drop } from '../../../api/slogong'
import { Cangsou, Houtaipi } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rows: {}, // 详情数据
      storelist: [], // 仓库搜索
      house: '', // 仓库名称
      yanuser: '', // 验收人
      yanlist: [], // 验收人列表
      timeFrom: [], // 时间筛选数组
      tableData: [], // 列表数据
      yanid: '', // 验收人id
      from_time: '', // 开始时间
      end_time: '', // 结束时间
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      dialogVisible: false
    }
  },
  mounted() {
    this.Houtaipilist()
  },
  methods: {
    // 导出文件
    daochu() {
      const urls = this.rows.pdf_url + '? response-content-type=application/octet-stream'
      const x = new window.XMLHttpRequest()
      x.open('GET', urls, true)
      x.responseType = 'blob'
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.rows.batch_no}`
        a.click()
      }
      x.send()
    },
    // 对话框关闭
    handleClose() {
      this.dialogVisible = false
    },
    // 点击详情
    editfun(row) {
      this.dialogVisible = true
      this.rows = row
    },

    // 日期选中事件
    gettime(timeFrom) {
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.Houtaipilist()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.Houtaipilist()
      }
    },

    // 仓库搜索清空
    cangqing() {
      this.house = ''
      this.Houtaipilist()
    },

    // 验收清空
    yanqing() {
      this.yanuser = ''
      this.yanid = ''
      this.Houtaipilist()
    },

    // 搜索仓库
    cangvalidateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },

    // 搜索仓库选中
    canghandleSelect(e) {
      this.Houtaipilist()
    },

    // 搜索验收人后的数据
    yanvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 选中验收人
    yanhandleSelect(e, val) {
      this.yanid = e.value
      this.Houtaipilist()
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Houtaipilist()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Houtaipilist()
    },

    // 仓库搜索
    async Cang(name, cd) {
      const { data } = await Cangsou({ name })
      // console.log(data.data)
      this.storelist = data.data
      cd(data.data)
    },

    // 验收人
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.yanlist = data.data
      cd(data.data)
    },

    async Houtaipilist() {
      const { data } = await Houtaipi({ ...this.pageData, house_name: this.house, receive_user: this.yanid, from_time: this.from_time, end_time: this.end_time })
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    }
  }
}
</script>

<style scoped>
.tops {
  margin-left: 30px;
}
.top {
  display: flex;
  align-items: center;
}
.let {
  width: 80px;
  margin-left: 15px;
}
.tan {
  display: flex;
  align-items: center;
}
.xianglist {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.nav {
  width: 100%;
  display: flex;
  align-items: center;
}
.nav-btn {
  margin-left: 50px;
}
</style>